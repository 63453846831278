import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  Col,
  Form,
  Row,
  Typography,
  Input,
  Button,
  Divider,
  Pagination,
  Space,
  Select,
  Empty,
  Spin,
} from "antd"
import "./style.scss"
import { debounce } from "lodash"

import { ListProductsContext } from "."

import { AccountContext } from "../../../context/account"
import { getAllProductTag } from "../../../services/user"
import { getListCompany } from "../../../services/superAdManageCompany"

export default function SearchTableListProduct({ debounceTimeout = 500 }) {
  const { collections } = useContext(AccountContext)

  const [collectionsMax, setCollectionsMax] = useState()
  const [collectionsMedium, setCollectionsMedium] = useState()
  const [collectionsSmall, setCollectionsSmall] = useState()
  const { setSearchParams, isLoading } = useContext(ListProductsContext)
  const [form] = Form.useForm()
  const fetchRef = useRef(0)

  const onFinish = (values) => {
    setSearchParams(values)
  }

  useEffect(() => {
    setCollectionsMax(collections)
  }, [collections])

  const handleCollectionMax = (value) => {
    form.setFieldsValue({ selectCollectionMedium: undefined })
    form.setFieldsValue({ selectCollectionSmall: undefined })
    setCollectionsSmall([])
    const data = collectionsMax.find((col) => col.id == value)
    setCollectionsMedium(data ? data.children : "")
  }

  const onSecondCityChange = (value) => {
    form.setFieldsValue({ selectCollectionSmall: undefined })
    const data = collectionsMedium.find((col) => col.id == value)
    setCollectionsSmall(data ? data.children : "")
  }

  const [dataOrigin, setDataOrigin] = useState(null)

  const getProductTags = useCallback(async () => {
    const res = await getAllProductTag({ typeTag: "origin" })
    if (res.data) {
      setDataOrigin(res.data?.productTag)
    }
  }, [])

  useEffect(() => {
    if (!dataOrigin) {
      getProductTags()
    }
  }, [])

  const [isLoadingCompany, setIsLoadingCompany] = useState(false)
  const [listCompany, setListCompany] = useState([])
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const [haveMore, setHaveMore] = useState(true)

  const [searchParamsCompany, setSearchParamsCompany] = useState(null)

  const handlegetListCompany = async () => {
    setIsLoadingCompany(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      is_have_products: true,
      q: searchParamsCompany ? searchParamsCompany : null,
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      setListCompany(response.data.stores)
      if (response.data.stores?.length < 10) {
        setHaveMore(false)
      }
      if (response.data.stores?.length == 10) {
        setHaveMore(true)
      }
      setIsLoadingCompany(false)
    }
  }
  useEffect(() => {
    handlegetListCompany()
  }, [searchParamsCompany])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setListCompany([])
      tableParams.pagination.current = 1
      setTableParams({ ...tableParams })
      setSearchParamsCompany(value)
    }
    return debounce(loadOptions, debounceTimeout)
  }, [handlegetListCompany, debounceTimeout])

  const onScroll = async (event) => {
    if (!haveMore) {
      return
    }
    const target = event.target
    if (
      !isLoadingCompany &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("load")

      target.scrollTo(0, target.scrollHeight)

      tableParams.pagination.current = tableParams.pagination.current + 1
      setIsLoadingCompany(true)

      setTableParams({ ...tableParams })

      const dataSent = {
        offset:
          (tableParams.pagination.current - 1) *
          tableParams.pagination?.pageSize,
        limit: tableParams.pagination.pageSize,
        is_have_products: true,

        q: searchParamsCompany ? searchParamsCompany : null,
      }
      const { response } = await getListCompany(dataSent)
      if (response.status == 200) {
        if (response.data.stores?.length < 10) {
          setHaveMore(false)
        }
        if (response.data.stores?.length == 10) {
          setHaveMore(true)
        }

        setListCompany([...listCompany, ...response.data.stores])
        setIsLoadingCompany(false)
      }
    }
  }

  return (
    <div className="customFormSearch">
      <Form
        colon={false}
        onFinish={onFinish}
        style={{ backgroundColor: "#FAFAFA", padding: "2rem" }}
        labelCol={{ span: 4 }}
        form={form}
        requiredMark={false}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
      >
        <Row className="mb-3">
          <Typography style={{ color: "#9B9B9B" }} className="font-medium">
            絞り込み条件
          </Typography>
        </Row>
        <Spin spinning={collections?.length == 0}>
          {" "}
          <Row>
            <Col span={12}>
              {collections?.length > 0 && (
                <Form.Item
                  label={
                    <Typography
                      style={{ display: "flex", width: "85px" }}
                      className="font-semibold mt-1"
                    >
                      カテゴリ
                    </Typography>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 10,
                    }}
                  >
                    <Form.Item
                      name="selectCollectionMax"
                      className=" flex flex-1  [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select w-full"
                        placeholder="大カテゴリ"
                        showSearch
                        allowClear
                        onChange={handleCollectionMax}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMax &&
                          collectionsMax.map((great) => (
                            <Select.Option value={great.id} key={great.id}>
                              {great.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionMedium"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className=" select_search-select"
                        onChange={onSecondCityChange}
                        placeholder="中カテゴリ"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMedium &&
                          collectionsMedium.map((git) => (
                            <Select.Option value={git.id} key={git.id}>
                              {git.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionSmall"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select"
                        placeholder="小カテゴリ"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsSmall &&
                          collectionsSmall.map((small) => (
                            <Select.Option value={small.id} key={small.id}>
                              {small.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="origin"
                allowClear
                label={
                  <Typography
                    className="font-semibold"
                    style={{ display: "flex", width: "93px" }}
                  >
                    産地
                  </Typography>
                }
              >
                <Select
                  mode="multiple"
                  allowClear
                  className="fix_antd_remove_selection"
                  placeholder="産地を選択してください"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataOrigin &&
                    dataOrigin.map((great) => (
                      <Select.Option value={great.id} key={great.id}>
                        {great.value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Form.Item
              name="company"
              allowClear
              label={
                <Typography
                  className="font-semibold"
                  style={{ display: "flex" }}
                >
                  出品企業
                </Typography>
              }
            >
              <Select
                mode="multiple"
                allowClear
                className="fix_antd_remove_selection"
                placeholder="出品企業を選択してください"
                filterOption={false}
                onSearch={debounceFetcher}
                onPopupScroll={onScroll}
                onBlur={() => {
                  tableParams.pagination.current = 1
                  setTableParams({ ...tableParams })
                  setSearchParamsCompany(null)
                }}
              >
                {isLoadingCompany
                  ? [
                      ...listCompany.map((great) => (
                        <Select.Option value={great.store_id} key={great.id}>
                          {great.name}
                        </Select.Option>
                      )),
                      <Select.Option key="loading" disabled={true}>
                        <Spin size="small" />
                      </Select.Option>,
                    ]
                  : listCompany.map((great) => (
                      <Select.Option value={great.store_id} key={great.id}>
                        {great.name}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="keyword"
              allowClear
              label={
                <Typography
                  className="font-semibold"
                  style={{ display: "flex" }}
                >
                  キーワード
                </Typography>
              }
            >
              <Input placeholder="キーワードで探す" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row className="justify-center pt-8">
          <Button
            htmlType="submit"
            className="w-36 ml-[10px]"
            type="primary"
            loading={isLoading}
          >
            検索
          </Button>
        </Row>
      </Form>
    </div>
  )
}
