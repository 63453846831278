import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "gatsby"
import { Content } from "antd/lib/layout/layout"
import { Breadcrumb, Button, Typography } from "antd"
import SearchTableListProduct from "./SearchTableListProduct"
import TableProduct from "./TableProduct"
import { getListProductForSPAdmin } from "../../../services/product"
export const ListProductsContext = React.createContext()
const { Title } = Typography

function TaxForProduct(props) {
  const [isLoading, setIsLoading] = useState(false)

  const [total, setTotal] = useState(0)
  const [dataProducts, setDataProducts] = useState(null)
  const [searchParams, setSearchParams] = useState({})

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const handlegetListProduct = async (value) => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams.keyword,
      tags: searchParams.origin?.length > 0 ? searchParams.origin : null,
      collection_id: searchParams.selectCollectionSmall
        ? [searchParams.selectCollectionSmall]
        : searchParams.selectCollectionMedium
        ? [searchParams.selectCollectionMedium]
        : searchParams.selectCollectionMax
        ? [searchParams.selectCollectionMax]
        : null,
      store_id: searchParams.company?.length > 0 ? searchParams.company : null,
    }
    const { response } = await getListProductForSPAdmin(dataSent)
    if (response.status == 200) {
      setDataProducts(response.data.products)
      setTotal(response.data.count)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlegetListProduct()
  }, [])

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })
  }, [searchParams])

  useEffect(() => {
    if (dataProducts) {
      handlegetListProduct()
    }
  }, [tableParams])

  const data = {
    total,
    isLoading,
    setTotal,
    tableParams,
    setTableParams,
    dataProducts,
    setSearchParams,
    setDataProducts,
  }
  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
            color: "#111111",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-ad-tax-management">消費税管理</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>商品消費税を設定する</Breadcrumb.Item>
          </Breadcrumb>

          <div className="flex justify-between w-full">
            <Title
              level={2}
              className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
            >
              消費税管理 - 商品消費税を設定する
            </Title>
          </div>
          <div>
            <ListProductsContext.Provider value={data}>
              <SearchTableListProduct />
              <TableProduct />
            </ListProductsContext.Provider>
          </div>
        </div>
      </Content>
    </div>
  )
}

export default TaxForProduct
