import React, { useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { onKeyPressNumber } from "../../../customUtil/global"
import { deleteTaxFee, updateTaxFee } from "../../../services/taxFee"
import textAndRules from "../../../utils/textAndRules"
const UpdateFee = ({ record }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)
  const [disable, setDisable] = useState(!(record.mid_code == "true"))
  const handleUpdateTaxFee = async () => {
    setIsLoading(true)
    const { response } = await updateTaxFee({
      fee: Number(numberAfter),
      type: "taxFee",
      productId: record.id,
    })
    if (response.status == 200) {
      setIsLoading(false)
      setNumberBefore(numberAfter)
      setDisable(false)
      openNotificationChangeSuccess(textAndRules.updateSuccess, "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
    }
  }
  useEffect(() => {
    setNumberBefore(record.material)
    setNumberAfter(record.material)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 100, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Button
          style={{ height: 25, fontSize: 10 }}
          danger
          disabled={numberAfter == numberBefore}
          loading={isLoading}
          onClick={handleUpdateTaxFee}
        >
          {"編集"}
        </Button>

        <Popconfirm
          placement="bottomLeft"
          title={
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              {`本当に実行しますか？ `}
            </div>
          }
          onConfirm={async () => {
            setIsLoadingDelete(true)
            const { response } = await deleteTaxFee({
              type: "taxFee",
              productId: record.id,
            })
            if (response.status == 200) {
              setIsLoadingDelete(false)
              setNumberBefore(null)
              setNumberAfter(null)
              setDisable(true)
              openNotificationChangeSuccess(
                textAndRules.updateSuccess,
                "",
                "#389e0d"
              )
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
            }
          }}
          okText="削除"
          cancelText="キャンセル"
          disabled={disable}
        >
          <Button
            style={{ height: 25, fontSize: 10 }}
            loading={isLoadingDelete}
            disabled={disable}
          >
            {"削除"}
          </Button>
        </Popconfirm>
      </Space>
    </div>
  )
}

export default UpdateFee
