import { Button, Switch, Image, Table } from "antd"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import { ListProductsContext } from "."
import { itemRender } from "../../../customUtil/table"
import "../style.scss"
import NoImg from "../../../images/no_image.jpg"
import UpdateFee from "./UpdateFee"

const TableProduct = () => {
  const {
    dataProducts,
    isLoading,
    total,
    tableParams,
    setTableParams,
  } = useContext(ListProductsContext)

  const columns = [
    {
      title: "商品画像",
      dataIndex: "thumbnail",
      width: 100,
      render: (_, record) => (
        <Image
          style={{ width: "60px", height: "35px", objectFit: "cover" }}
          alt={"thumbnail"}
          src={record?.thumbnail || NoImg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImg
          }}
        />
      ),
    },

    {
      title: "掲載企業名",
      dataIndex: "store_name",
      width: 150,
      render: (text) => <p className="textCustom">{text}</p>,
    },

    {
      title: "商品名",
      dataIndex: "title",
      width: "226px",
      render: (text) => <p className="textCustom">{text}</p>,
    },
    {
      title: (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{"手数料率"}</div>
          {/* <Space size={5} direction="horizontal">
              <Input style={{ width: 46, height: 25 }} />
              <div>{"%"}</div>
              <Button style={{ height: 25, fontSize: 10 }} danger>
                {"一括設定"}
              </Button>
            </Space> */}
        </div>
      ),
      width: 220,

      render: (_, record) => {
        return <UpdateFee record={record} />
      },
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }
  return (
    <div className=" mt-5">
      <Table
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={dataProducts}
        locale={{
          emptyText: "検索結果は０です。",
        }}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: itemRender,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default TableProduct
